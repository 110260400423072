export default [
  {
    title: 'NO',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'vendor_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'vendor_name',
  },
  {
    title: 'Tipe',
    dataIndex: 'type',
  },
  {
    title: 'Fungsi',
    dataIndex: 'function',
  },
  {
    title: 'URL',
    dataIndex: 'url',
  },
  {
    title: 'Method',
    dataIndex: 'method',
  },
  {
    title: 'Token',
    dataIndex: 'token',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
